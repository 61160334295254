<template>
  <q-card flat class="justify-center">
    <q-dialog v-model="modalSend" persistent>
      <q-card>
        <q-card-section>
          <div v-if="sendTest" class="text-h6">
            Email teste enviado para: <b>{{ user.email }}</b>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            class="q-px-md"
            no-caps
            label="OK"
            color="primary"
            v-close-popup
            @click="() => this.$emit('tabListing')"
          />
        </q-card-actions>
        <q-card-section class="q-pt-none" />
      </q-card>
    </q-dialog>

    <q-form @submit="sendMail">
      <q-card-section class="row q-col-gutter-md">
        <div class="col-4">
          <q-select
            v-model="params.campaignSelected"
            :bg-color="params.campaignSelected?.length === 0 ? 'yellow-3' : ''"
            :options="campaignsFiltered"
            label="Selecione a(s) Campanha(s)"
            outlined
            option-label="name"
            option-value="id"
            clear-icon="mdi-close"
            clearable
            use-chips
            multiple
            input-debounce="0"
            @filter="filterCampaigns"
            @input="handleCampaignSelection"
            @clear="handleCampaignClear"
          />
        </div>
        <div class="col-4">
          <q-select
            :disable="!isSingleCampaignSelected"
            v-model="params.channelSelected"
            :options="this.channels.filter((item) => item.campaign_site_status <= 1)"
            label="Escolha o Canal ou deixe em branco para todos"
            multiple
            use-chips
            outlined
            option-label="site_name"
            option-value="site_id"
            clear-icon="mdi-close"
            clearable
            use-input
          >
            <template v-slot:selected-item="scope">
              <q-chip
                removable
                size="0.8rem"
                @remove="scope.removeAtIndex(scope.index)"
                :tabindex="scope.tabindex"
                color="primary"
                text-color="white"
                square
                icon-remove="close"
                input-class="text-caption"
              >
                {{ scope.opt.site_name }}
              </q-chip>
            </template>
          </q-select>
        </div>
        <div class="col-4">
          <q-select
            v-model="params.mailing_type_id"
            ref="type"
            label="Tipo"
            outlined
            clearable
            clear-icon="mdi-close"
            :options="mailingTypeOptions"
            :rules="params.mailing_type_id == null ? [isRequired] : $refs.type.resetValidation()"
            error-message="É obrigatório selecionar um tipo de Mailing"
          />
        </div>
        <div class="col-12">
          <q-input
            ref="email"
            v-model="params.email"
            label="Insira o email. Para mais de um separe-os com ;"
            outlined
            clearable
            clear-icon="mdi-close"
            :rules="params.campaignSelected.length === 0 ? [isRequired] : $refs.email.resetValidation()"
            lazy-rules
            error-message="Insira um email válido ou selecione uma campanha"
          />
        </div>
        <div class="col-12">
          <q-input ref="subject" outlined type="text" v-model="params.subject" label="Assunto"> </q-input>
        </div>
        <div class="col-12">
          <q-input
            ref="html"
            v-model="params.html"
            outlined
            type="textarea"
            lazy-rules
            rows="10"
            placeholder="Cole aqui o HTML"
          />
        </div>

        <div class="col-12 q-py-md row justify-end">
          <q-btn color="positive" label="Visualizar" no-caps unelevated class="col-2" @click="viewMail" />
          <q-btn color="positive" label="Salvar" no-caps unelevated class="col-2 q-ml-md" @click="saveMail" />
          <q-btn
            color="positive"
            label="Disparar Teste"
            no-caps
            unelevated
            class="col-2 q-ml-md"
            @click="sendtestMail"
          />
          <q-btn color="primary" label="Enviar" no-caps unelevated type="submit" class="col-2 q-ml-md" />
        </div>
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script>
import CampaignMixin from '../../../mixins/CampaignMixin';
export default {
  mixins: [CampaignMixin],
  name: 'MailingContentRegistration',

  props: {
    campaignList: []
  },

  data() {
    return {
      sendTest: false,
      modalSend: false,
      campaignsFiltered: [],

      params: {
        campaignSelected: [],
        channelSelected: [],
        email: '',
        subject: '',
        html: '',
        mailing_type_id: null
      },
      mailingTypeOptions: []
    };
  },

  computed: {
    isSingleCampaignSelected() {
      return this.params.campaignSelected.length === 1;
    }
  },

  created() {
    this.loadMailingTypes();
  },

  methods: {
    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignList;
        this.campaignsFiltered = this.campaignList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    handleCampaignSelection() {
      if (this.params.campaignSelected.length === 1) {
        this.getChannelsById(this.params.campaignSelected[0].id);
      } else {
        this.params.channelSelected = [];
        this.getChannelsById();
      }
    },

    handleCampaignClear() {
      this.params.channelSelected = [];
      this.getChannelsById();
    },

    viewMail() {
      if (this.params.html) {
        this.$emit('viewMail', this.params.html);
      } else {
        this.errorNotify('Cole o HTML antes de visualizar');
      }
    },

    async saveMail(e) {
      this.onLoading(true);
      if (!this.params.email && this.params.campaignSelected.length === 0) {
        this.errorNotify('Insira o email ou selecione uma campanha');
        this.onLoading(false);
        this.$refs.email.focus();
        return;
      }
      if (!this.params.subject) {
        this.errorNotify('Digite o assunto antes de salvar');
        this.onLoading(false);
        this.$refs.subject.focus();
        return;
      }
      if (!this.params.html) {
        this.errorNotify('Cole o HTML');
        this.onLoading(false);
        this.$refs.html.focus();
        return;
      }
      let lastCaracter = this.params.email.charAt(this.params.email.length - 1);
      if (lastCaracter == ';') this.params.email = this.params.email.slice(0, -1);

      try {
        const campaignIds = this.params.campaignSelected.map((campaign) => campaign.id).join(',');
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: `affiliate`,
          path: '/api/mailing/',
          params: {
            data: {
              subject: this.params.subject,
              content: this.params.html,
              emails: this.params.email,
              campaign_id: campaignIds,
              sites: this.params.channelSelected.map((v) => v.site_id),
              mailing_type_id:  this.params.mailing_type_id.value
            }
          }
        });

        if (status === 200 || status === 201) {
          if (e != 'send') this.successNotify('Mailing cadastrado com sucesso!');
          else this.successNotify('O envio do mailling está sendo processado.');
          this.clear();
          return data.id;
        }
      } catch (error) {
        console.log(error);
        this.errorNotify('Falha ao salvar!');
      } finally {
        this.onLoading(false);
      }
    },

    clear() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : '';
    },

    async sendtestMail() {
      let idMailing;
      idMailing = await this.saveMail();

      if (idMailing) {
        this.onLoading(true);
        try {
          const { status } = await this.$http.post(`/v2/redirect`, {
            app: `affiliate`,
            path: `/api/mailing/sendTest`,
            params: {
              data: {
                emails: this.user.email,
                id: `${idMailing}`
              }
            }
          });

          if (status === 200 || status === 201) {
            this.sendTest = true;
            this.modalSend = true;
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }
      }
    },

    async sendMail() {
      let idMailing;
      idMailing = await this.saveMail('send');

      if (idMailing) {
        this.onLoading(true);
        try {
          const { status } = await this.$http.post(`/v2/redirect`, {
            app: `affiliate`,
            path: '/api/mailing/send',
            params: {
              data: {
                id: `${idMailing}`
              }
            }
          });

          if (status === 200 || status === 201) {
            this.sendTest = false;
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }
      }
    },

    async loadMailingTypes() {
      try {
        const response = await this.$http.get('redirect?app=AFFILIATE&path=/api/mailing-types');
        this.mailingTypeOptions = response.data.map(item => ({
          value: item.id,
          label: item.name
        }));
      } catch (error) {
        console.error('Falha ao carregar tipos de mailing', error);
      }
    }
  }
};
</script>